import gridComponentBase from "@/components/grid/gridComponentBase.vue";
import helpers from "@/helpers";
import api from "@/services/api";
import { Component } from "vue-property-decorator";

@Component({})
export default class TerminiECondizioni extends gridComponentBase {
	created() {
		this.getTerminiECondizioni(this.$route.query.pacchettoId, this.$route.query.prodottoId)
	}

	async getTerminiECondizioni(pacchettoID, prodottoID) {
		var self = this;

		
		helpers.getTerminiECondizioni(this,pacchettoID, prodottoID);

		/*api.getWithAllDataResponse(`${api.endpoint.CARRELLO.TERMINI_E_CONDIZIONI}?pacchettoId=${pacchettoID}&prodottoId=${prodottoID}`, { responseType: "blob" })
		.then(res => {
			const blob = new Blob([res.data], { type: res.headers['content-type'] });

			if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
                (window.navigator as any).msSaveOrOpenBlob(blob);
            } else {
                var fileURL = URL.createObjectURL(blob);
                window.open(fileURL, "_self");
            }
		}).catch(err => {
			self.$bvToast.toast(self.$i18n.t("errore.fileNotFound").toString(), {
				variant: "danger",
				title: self.$i18n.t("generico.errore").toString(),
				solid: true
			});
		});*/
	}
}